<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <UserForm :user_id="user_id" @submitForm="submitForm"></UserForm>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";
export default {
  name: "UserEdit",
  components: {
    UserForm: () => import("./UserDetail"),
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
    };
  },
  computed: {
    user_id: function () {
      return this.$route.params.id;
    },
  },
  methods: {
    submitForm(data) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to save?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, do it!",
      }).then((result) => {
        if (result.value) {
          vm.is_call_api = true;
          ApiService.post("prep-app/user/" + vm.user_id, data)
            .then(function (res) {
              if (res.status === 202) {
                vm.$toasted.success("Submit successfully!!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                // vm.$router.push({ name: 'UserList'})
                vm.$router.go(-1);
                vm.is_call_api = false;
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              if (error.response.status === 422) {
                let data_response_error =
                  error.response.data.error.message.split(".<br />");
                vm.showError(data_response_error);
              }
            });
        }
      });
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
  },
};
</script>

<style></style>
